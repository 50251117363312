define("additive-account/routes/instances/instance/settings", ["exports", "@ember/routing/route", "@ember/service", "@ember/object", "ember-concurrency", "additive-account/mixins/global-user", "@additive-apps/ui/constants"], function (_exports, _route, _service, _object, _emberConcurrency, _globalUser, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _route.default.extend(_globalUser.default, {
    authenticatedFetch: (0, _service.inject)(),
    currentUser: (0, _service.inject)(),
    intl: (0, _service.inject)(),
    _setRouteTabs: (0, _emberConcurrency.task)(function* (controller) {
      const tabs = this._getTabs();
      try {
        var _this$globalUser, _this$globalUser2, _this$currentUser, _this$currentUser2;
        const intl = this.intl;
        yield this.currentUser.loadUser();
        const apps = yield this.store.query('app', {
          accessible: 1
        });
        const crm = apps.find(app => app.id && parseInt(app.id) === _constants.APP_ID_CRM);
        if (crm) {
          tabs.push({
            name: intl.t('routes.settings.tabs.roomCounts'),
            path: 'instances.instance.settings.room-counts'
          });
        }
        const isAdditive = (_this$globalUser = this.globalUser) === null || _this$globalUser === void 0 ? void 0 : _this$globalUser.isAdditive;
        const isPartner = (_this$globalUser2 = this.globalUser) === null || _this$globalUser2 === void 0 ? void 0 : _this$globalUser2.isOrgPartner;
        const hasPartnerPrivileges = isAdditive || isPartner && ((_this$currentUser = this.currentUser) === null || _this$currentUser === void 0 ? void 0 : _this$currentUser.isAdmin);
        const hasOrgDeactivationPrivileges = hasPartnerPrivileges || ((_this$currentUser2 = this.currentUser) === null || _this$currentUser2 === void 0 || (_this$currentUser2 = _this$currentUser2.user) === null || _this$currentUser2 === void 0 ? void 0 : _this$currentUser2.isSuperAdmin);
        if (hasOrgDeactivationPrivileges) {
          tabs.push({
            name: intl.t('routes.settings.tabs.organizationSettings'),
            path: 'instances.instance.settings.organization-settings'
          });
        }
        (0, _object.set)(controller, 'routableTabs', tabs);
      } catch (e) {
        (0, _object.set)(controller, 'routableTabs', tabs);
      }
    }),
    setupController(controller) {
      this._super(...arguments);
      this._setRouteTabs.perform(controller);
    },
    resetController(controller) {
      (0, _object.set)(controller, 'routableTabs', this._getTabs());
    },
    _getTabs() {
      const intl = this.intl;
      return [{
        name: intl.t('routes.settings.tabs.communication'),
        path: 'instances.instance.settings.communication'
      }, {
        name: intl.t('routes.settings.tabs.legals'),
        path: 'instances.instance.settings.legals'
      }, {
        name: intl.t('routes.settings.tabs.integrations'),
        path: 'instances.instance.settings.integrations'
      }, {
        name: intl.t('routes.settings.tabs.closingPeriods'),
        path: 'instances.instance.settings.closing-periods'
      }];
    }
  });
});